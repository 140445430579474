import React from 'react';
import gStyles from '../../global.module.css';
import styles from './contact.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';
import Animate from 'react-smooth';
import HamburgerMenu from '../../components/hamburger_menu';
import ContactLeft from './contactLeft';
import EmailForm from './emailForm';
import { Helmet } from 'react-helmet';
import particles from '../../const/contact_particle.js';
import Particles from 'react-particles-js';
import ReactGA from 'react-ga4';
// import Notification from '../../components/notification';
class Contact extends React.Component {
  componentDidMount() {
    ReactGA.pageview('Contact');
  }
  render() {
    const {
      contactHeader,
      hamburgerMenu,
      contactMain,
      contactLeft,
      contactRight,
      contactFooter,
    } = styles;
    return (
      <div>
        {/* <Notification /> */}
        <Helmet>
          <meta charSet='utf-8' />
          <title>Contact | Nathan Birch</title>
          <link rel='canonical' href='https://nathanbirch.one/contact' />
          <meta
            name='description'
            content='Nathan Birch | Contact - Product Manager | Project Manager | Software Engineer'
          />
        </Helmet>
        <Animate to='1' from='0' attributeName='opacity'>
          <Particles params={particles} className={gStyles.particle} />
          <Container className={contactHeader} fluid={true}>
            <Row className={hamburgerMenu}>
              <HamburgerMenu />
            </Row>
            <Row className={contactMain}>
              <Col xl={6} className={contactLeft}>
                <ContactLeft />
              </Col>
              <Col xl={6} className={contactRight}>
                <EmailForm />
              </Col>
            </Row>
            <Row className={contactFooter}>
              <Footer />
            </Row>
          </Container>
        </Animate>
      </div>
    );
  }
}
export default Contact;
