import React, { useEffect, useState } from 'react';
import styles from './home.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop';
import Colors from '../../const/colors';
import LinkButton from '../../components/buttons/linkButton';
import ReactGA from 'react-ga4';

function Home_Left() {
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [count]);

  const gaClick = (button) => {
    ReactGA.event({
      category: 'Home Page',
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  };

  let titles = [
    'Staff Software Engineer',
    'Full Stack Developer',
    'Computer Science Professor',
    'Software Engineer',
    'Curriculum Designer',
    'Dev Team Lead',
    'Mobile App Developer',
    'Project Manager',
    'Dad Joke Master',
  ];
  const titlesWithStyle = titles.map((t) => {
    var text = t.trim().split(' ');
    var last = text.pop();
    return { start: text.join(' '), end: ' ' + last, l: t.length };
  });

  const getStyle = () => {
    return {
      color: Colors[Math.floor(Math.random() * Colors.length)],
    };
  };
  const {
    homeLeft,
    homeLeftMain,
    homeLeftMainCol,
    firstLine,
    secondLine,
    minH,
  } = styles;
  return (
    <Container className={homeLeft}>
      <Row className={homeLeftMain}>
        <Col xl={12} className={homeLeftMainCol}>
          <h1 className={firstLine}>Hello!</h1>
          <h2 className={secondLine}>
            I am <strong>nathan birch</strong>
          </h2>
          <h3 className={minH}>
            <TypistLoop interval={500}>
              {titlesWithStyle.map((t) => (
                <Typist key={t.start} avgTypingDelay={50}>
                  {t.start}
                  <span style={getStyle()}>{t.end}</span>
                  <Typist.Backspace
                    key={Math.random()}
                    count={t.l}
                    delay={900}
                  />
                </Typist>
              ))}
            </TypistLoop>
          </h3>
          <br />
          <LinkButton
            path={'/about'}
            text={'About Me'}
            onClick={() => {
              gaClick('About Me');
            }}
          />
          &nbsp;
          <LinkButton
            path={'/contact'}
            text={'Contact Me'}
            onClick={() => {
              gaClick('Contact Me');
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Home_Left;
