import React from 'react';
import styles from './resume.module.css';
import Colors from '../../../const/colors';
// import Notification from '../../../components/notification';

export default function ResumeHeader() {
  const { printOnly, centered, maskBackground, makeBold } = styles;

  return (
    <>
      {/* <Notification /> */}
      <div className={printOnly}>
        <h1 className={`display-1 ${centered}`}>
          <span className={`${maskBackground} ${makeBold}`}>Nathan Birch</span>
        </h1>
        <span className='printOnly'>
          <hr
            style={{
              color: Colors[1],
              backgroundColor: Colors[1],
              height: 2,
              width: '50%',
              marginTop: '-10px',
              marginBottom: '10px',
            }}
          />
        </span>
        <h6 className={`display-6 ${centered}`}>
          <span className={maskBackground}>nathanbirch.one/contact</span>
        </h6>
        <br />
      </div>
    </>
  );
}
